export default {
  namespaced: true,
  state: {
    keyword: '',
    page: 1,
  },
  getters: {
    keyword(state) {
      return state.keyword
    },
    page(state) {
      return state.page
    }
  },
  mutations: {
    UPDATE_KEYWORD(state, val) {
      state.keyword = val
    },
    UPDATE_PAGE(state, val) {
      state.page = val
    },
  },
  actions: {},
}
