
export default {
    methods:{
      test(data) {
        console.log("test")
        this.$socket.emit('chat message', data)
      },
      test2() {
        console.log("FUCK")
      }
    },
    data() {
      return {
        reader_action : normal
      }
    }
}
