export default [
  {
    path: '/apps/config/',
    name: 'system-configuration',
    component: () => import('@/views/cloudfit/config/configEdit.vue'),
    meta: {
      pageTitle: 'System Configuration 系統設定',
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/apps/config/renew/',
    name: 'config-renew',
    component: () => import('@/views/cloudfit/config/plan.vue'),
    meta: {
      pageTitle: 'Renewal',
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/apps/config/renew-payment/order/:order_id/',
    name: 'renew-order',
    component: () => import('@/views/cloudfit/config/order.vue'),
    meta: {
      pageTitle: 'Renewal',
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/card_link/:center_id/:token/',
    name: 'card_link',
    component: () => import('@/views/pages/credit_card_link.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/account-register/',
    name: 'account-register',
    component: () => import('@/views/pages/authentication/account-register.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/register/:center_code/',
    name: 'register',
    component: () => import('@/views/pages/register.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/paylink/daypass/:plan_uuid/',
    name: 'paylink-daypass',
    component: () => import('@/views/pages/pay_link_daypass.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/paylink/class/:class_uuid/:mobile/',
    name: 'paylink-class',
    component: () => import('@/views/pages/pay_link_class.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/pay_link/:paylink_token/',
    name: 'paylink',
    component: () => import('@/views/pages/pay_link.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/epad/:id',
    name: 'epad',
    component: () => import('@/views/cloudfit/epad/Epad.vue'),
    meta: {
    resource: 'ACL',
      action: 'read',
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/cloudfit/dashboard/Ecommerce.vue'),
    meta: {
    resource: 'ACL',
      action: 'read',
    }
  },
  {
    path: '/pt-detail/:id',
    name: 'pt-detail',
    component: () => import('@/views/cloudfit/package/Pt_Detail.vue'),
    meta: {
    resource: 'ACL',
      action: 'read',
    }
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import('@/views/cloudfit/calendar/Calendar.vue'),
    meta: {
    resource: 'ACL',
      action: 'read',
    }
  },
  {
    path: '/member-list',
    name: 'member-list',
    component: () => import('@/views/cloudfit/member/member_list.vue'),
    meta: {
      pageTitle: 'Member List 會員管理',
      breadcrumb: [
        {
          text: 'Member List 會員管理',
          active: true,
        },
      ],
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/staff-list',
    name: 'staff-list',
    component: () => import('@/views/cloudfit/staff/staff_list.vue'),
    meta: {
      pageTitle: 'User List 系統用戶管理',
      breadcrumb: [
        {
          text: 'User List 系統用戶管理',
          active: true,
        },
      ],
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/apps/staff/edit/:id',
    name: 'apps-staff-edit',
    component: () => import('@/views/cloudfit/staff/staff-edit/StaffEdit.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/apps/cloudfit/membership/:id',
    name: 'apps-membership',
    component: () => import('@/views/cloudfit/member/member-edit/membership-edit.vue'),
    meta: {
      pageTitle: 'Plan Detail',
      breadcrumb: [
        {
          text: 'Plan Detail',
          active: true,
        },
      ],
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/apps/cloudfit/package/:id',
    name: 'apps-package',
    component: () => import('@/views/cloudfit/package/Package.vue'),
    meta: {
      pageTitle: 'Package Record',
      breadcrumb: [
        {
          text: 'Package Record',
          active: true,
        },
      ],
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/apps/cloudfit/staff/package/:id',
    name: 'apps-package',
    component: () => import('@/views/cloudfit/staff/Package.vue'),
    meta: {
      pageTitle: 'Staff Package Record',
      breadcrumb: [
        {
          text: 'Staff Package Record',
          active: true,
        },
      ],
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/apps/member/edit/:id',
    name: 'apps-member-edit',
    component: () => import('@/views/cloudfit/member/member-edit/MemberEdit.vue'),
    meta: {
      pageTitle: 'Member Info 個人資料',
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/apps/member/:id/invoice/add/',
    name: 'apps-member-inovice-add',
    component: () => import('@/views/cloudfit/member/invoice/invoice-add.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/apps/member/:id/membership/add/',
    name: 'apps-member-membership-add',
    component: () => import('@/views/cloudfit/member/membership/add.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/news-list',
    name: 'news-list',
    component: () => import('@/views/cloudfit/member/member_list.vue'),
    meta: {
      pageTitle: 'Member List 會員管理',
      breadcrumb: [
        {
          text: 'Member List 會員管理',
          active: true,
        },
      ],
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/package-list',
    name: 'package-list',
    component: () => import('@/views/cloudfit/package/package_list.vue'),
    meta: {
      pageTitle: 'Package List 套票管理',
      breadcrumb: [
        {
          text: 'Package List 套票管理',
          active: true,
        },
      ],
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/membership-list',
    name: 'membership-list',
    component: () => import('@/views/cloudfit/membership/membership_list.vue'),
    meta: {
      pageTitle: 'Membership List 會籍管理',
      breadcrumb: [
        {
          text: 'Membership List 會籍管理',
          active: true,
        },
      ],
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/class-import',
    name: 'class-import',
    component: () => import('@/views/cloudfit/class/Import.vue'),
    meta: {
      pageTitle: 'Import',
      breadcrumb: [
        {
          text: 'Import',
          active: true,
        },
      ],
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/system-config',
    name: 'system-config',
    component: () => import('@/views/cloudfit/member/member_list.vue'),
    meta: {
      pageTitle: 'Member List 會員管理',
      breadcrumb: [
        {
          text: 'Member List 會員管理',
          active: true,
        },
      ],
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/class-list',
    name: 'class-list',
    component: () => import('@/views/cloudfit/class/Class.vue'),
    meta: {
      pageTitle: 'Class List 課堂管理',
      breadcrumb: [
        {
          text: 'Class List 課堂管理',
          active: true,
        },
      ],
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/product-list',
    name: 'product-list',
    component: () => import('@/views/cloudfit/product/product.vue'),
    meta: {
      pageTitle: 'Product List 貨品管理',
      breadcrumb: [
        {
          text: 'Product List 貨品管理',
          active: true,
        },
      ],
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/coupon-list',
    name: 'coupon-list',
    component: () => import('@/views/cloudfit/member/member_list.vue'),
    meta: {
      pageTitle: 'Member List 會員管理',
      breadcrumb: [
        {
          text: 'Member List 會員管理',
          active: true,
        },
      ],
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/product-list',
    name: 'product-list',
    component: () => import('@/views/cloudfit/member/member_list.vue'),
    meta: {
      pageTitle: 'Member List 會員管理',
      breadcrumb: [
        {
          text: 'Member List 會員管理',
          active: true,
        },
      ],
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/center-list',
    name: 'center-list',
    component: () => import('@/views/cloudfit/member/member_list.vue'),
    meta: {
      pageTitle: 'Member List 會員管理',
      breadcrumb: [
        {
          text: 'Member List 會員管理',
          active: true,
        },
      ],
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/room-list',
    name: 'room-list',
    component: () => import('@/views/cloudfit/room/Room.vue'),
    meta: {
      pageTitle: 'Room List 課室管理',
      breadcrumb: [
        {
          text: 'Room List 課室管理',
          active: true,
        },
      ],
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/report/:code',
    name: 'report',
    component: () => import('@/views/cloudfit/report/Report.vue'),
    meta: {
      pageTitle: 'Report',
      breadcrumb: [
        {
          text: 'Report',
          active: true,
        },
      ],
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/report/report-rental',
    name: 'report-rental',
    component: () => import('@/views/cloudfit/report/Report-rental.vue'),
    meta: {
      pageTitle: 'Report',
      breadcrumb: [
        {
          text: 'Report',
          active: true,
        },
      ],
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/report-transacion',
    name: 'report-transacion',
    component: () => import('@/views/cloudfit/report/Report-transaction.vue'),
    meta: {
      pageTitle: 'Report',
      breadcrumb: [
        {
          text: 'Report',
          active: true,
        },
      ],
      resource: 'ACL',
      action: 'read',
    },
  },
  
  {
    path: '/report-groupclass',
    name: 'report-groupclass',
    component: () => import('@/views/cloudfit/report/Report-groupclass.vue'),
    meta: {
      pageTitle: 'Report',
      breadcrumb: [
        {
          text: 'Report',
          active: true,
        },
      ],
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/report-class-schedule',
    name: 'report-class-schedule',
    component: () => import('@/views/cloudfit/report/Report-class-schedule.vue'),
    meta: {
      pageTitle: 'Report',
      breadcrumb: [
        {
          text: 'Report',
          active: true,
        },
      ],
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/report-groupclass-enrollment',
    name: 'report-groupclass-enrollment',
    component: () => import('@/views/cloudfit/report/Report-groupclass-enrollment.vue'),
    meta: {
      pageTitle: 'Report',
      breadcrumb: [
        {
          text: 'Report',
          active: true,
        },
      ],
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/report-pt-package',
    name: 'report-pt-package',
    component: () => import('@/views/cloudfit/report/Report-pt-package.vue'),
    meta: {
      pageTitle: 'Report',
      breadcrumb: [
        {
          text: 'Report',
          active: true,
        },
      ],
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/report-installment',
    name: 'report-installment',
    component: () => import('@/views/cloudfit/report/Report-installment.vue'),
    meta: {
      pageTitle: 'Report',
      breadcrumb: [
        {
          text: 'Report',
          active: true,
        },
      ],
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/report-entry',
    name: 'report-entry',
    component: () => import('@/views/cloudfit/report/Report-entry.vue'),
    meta: {
      pageTitle: 'Report',
      breadcrumb: [
        {
          text: 'Report',
          active: true,
        },
      ],
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/report-commission',
    name: 'report-commission',
    component: () => import('@/views/cloudfit/report/Report-commission.vue'),
    meta: {
      pageTitle: 'Report',
      breadcrumb: [
        {
          text: 'Report',
          active: true,
        },
      ],
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/report-membership',
    name: 'report-membership',
    component: () => import('@/views/cloudfit/report/Report-membership.vue'),
    meta: {
      pageTitle: 'Report',
      breadcrumb: [
        {
          text: 'Report',
          active: true,
        },
      ],
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/report-online-payment',
    name: 'report-online-payment',
    component: () => import('@/views/cloudfit/report/Report-online-payment.vue'),
    meta: {
      pageTitle: 'Report',
      breadcrumb: [
        {
          text: 'Report',
          active: true,
        },
      ],
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/report-nonpay',
    name: 'report-nonpay',
    component: () => import('@/views/cloudfit/report/Report-nonpay.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/report-personal-training',
    name: 'report-personal-training',
    component: () => import('@/views/cloudfit/report/Report-pt.vue'),
    meta: {
      pageTitle: 'Report',
      breadcrumb: [
        {
          text: 'Report',
          active: true,
        },
      ],
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/report-roster',
    name: 'report-roster',
    component: () => import('@/views/cloudfit/report/Report-roster.vue'),
    meta: {
      pageTitle: 'Report',
      breadcrumb: [
        {
          text: 'Report',
          active: true,
        },
      ],
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/report-product',
    name: 'report-product',
    component: () => import('@/views/cloudfit/report/Report-product.vue'),
    meta: {
      pageTitle: 'Report',
      breadcrumb: [
        {
          text: 'Report',
          active: true,
        },
      ],
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/expenditure',
    name: 'expenditure',
    component: () => import('@/views/cloudfit/expenditure/expenditure-list.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/invoice',
    name: 'invoice',
    component: () => import('@/views/cloudfit/invoice/invoice-list.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
]
