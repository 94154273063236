export default {
    namespaced: true,
    state: {
      calendars: []
    },
    getters: {
      selectedCalendar(state) {
        return state.calendars
      },
    },
    mutations: {
      UPDATE_CALENDAR(state, val) {
        state.id = val
      },
      UPDATE_CENTER_NAME(state, val) {
        state.name = val
      },
    },
    actions: {},
  }
  