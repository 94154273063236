import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
import VueSignature from "vue-signature-pad";
import VueHtmlToPaper from 'vue-html-to-paper';
import VueHtml2pdf from 'vue-html2pdf'
import VueSocketIO from 'vue-socket.io'
import socket from './@core/mixins/socket.js';

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'
const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css'
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}

Vue.use(VueHtmlToPaper, options);
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(VueSignature)
Vue.use(ModalPlugin)
Vue.use('vue-moment')
Vue.use(VueHtml2pdf)
// Composition API
Vue.use(VueCompositionAPI)
Vue.component('font-awesome-icon', FontAwesomeIcon)


// Vue.use(VueGtag, {
//   config: {
//       id: 'GTM-KP2ZTZ9T',
//       params: {
//           send_page_view: true
//       }
//   }
// }, router)


Vue.use(new VueSocketIO({
    debug: true,
    connection: 'https://www.cloudfit.hk:3210?reader=RC1234',
    vuex: {
        store,
        actionPrefix: 'SOCKET_',
        mutationPrefix: 'SOCKET_'
    },
}))

Vue.mixin({
  methods: {
    test: socket.methods.test,
    test2: socket.methods.test2

  },

})
// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
