export default {
    namespaced: true,
    state: {
      url: '',
    },
    getters: {
      currentUrl(state) {
        return state.url
      },
    },
    mutations: {
      UPDATE_URL(state, val) {
        state.url = val
      }
    },
    actions: {},
  }
  