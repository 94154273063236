export default {
  namespaced: true,
  state: {
    id: 0,
    name: '',
    last_person:'',
  },
  getters: {
    currentCenterId(state) {
      return state.id
    },
    currentCenterName(state) {
      return state.name
    },
    last_person(state) {
      return state.last_person
    },
  },
  mutations: {
    UPDATE_CENTER_ID(state, val) {
      state.id = val
    },
    UPDATE_CENTER_NAME(state, val) {
      state.name = val
    },
    UPDATE_LAST_PERSON(state, val) {
      state.last_person = val
    },
  },
  actions: {},
}
