import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns

Vue.prototype.$axiosGet = function(url){ 
  this.$http.get(url)
        .then(res => {
          console.log("1")
         return res
        })
        .catch(function(error) {
          console.log("in error")
          if (error.response && error.response.status === 401) {
            window.location.href = "login";
          } else {
            // Handle error however you want
          }
        });
 }
 Vue.prototype.$axiosGet2 = async function(url){ 
  
  await this.$http.get(url)
  .then(function (response) {
    // handle success
    console.log(response)
    new Promise((resolve, reject) => {
      return response
    })

  })
  .catch(function (error) {
    if (error.response && error.response.status === 401) {
      self.$route.replace("login")
    } else {
      // Handle error however you want
    }
  }) 
  .then(function () {
    // always executed
  });
 }


export default axiosIns
